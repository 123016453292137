import React, { useEffect, useRef, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCompanyContext } from '../../common/companyContext';
import { deleteLine, getLineList } from '../../utils/api.common';
import AppConformationModel from '../../widgets/AppConformationModel';
import { errorMessage, successMessage } from '../../common/Toast';
import EditIcon from '@mui/icons-material/Edit';
import EditExistingLine from './EditExistingLine';
import AddIcon from '@mui/icons-material/Add';
import AddLines from './AddLines';

function ManageLines() {
    const gridRef = useRef();
    const { selectedCompany } = useCompanyContext();
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [line_id, setLineId] = useState('')
    const [line_data, setLineData] = useState([])
    const [is_delete_conformation, setIsDeleteConformation] = useState(false)
    const [user_line_for_edit, setLineDataForEdit] = useState('')
    const [is_edit_conformation, setIsEditConformation] = useState(false)
    const [is_success_response, setIsSuccessResponse] = useState(false)
    const [is_add_new_line, setIsAddNewLine] = useState(false)

    useEffect(() => {
        (async () => {
            getCompanyLineList()
        })()
    }, [selectedCompany])

    useEffect(() => {
        (async () => {
            if (is_success_response === true) {
                getCompanyLineList()
                setIsSuccessResponse(false)
            }
        })()
    }, [is_success_response])

    const getCompanyLineList = async () => {
        try {
            const response = await getLineList(selectedCompany)
            response?.data?.data && setLineData(response?.data?.data)
        } catch (error) {
            console.error(error);
        }
    }

    const onEditConformation = (row_data) => {
        setLineId(row_data.data.id)
        setIsEditConformation(true)
        setLineDataForEdit(row_data.data)
    }

    const columnDefs = [
        {
            headerName: "S No.",
            valueGetter: "node.rowIndex + 1",
            width: '140',
            sortable: true
        },
        {
            headerName: 'Line Name',
            field: 'line',
            width: '250',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Line ID',
            field: 'id',
            width: '150',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Line Type',
            field: 'process',
            width: '250',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Company ID',
            field: 'company_id',
            width: '200',
            cellStyle: { borderLeft: '1px solid lightgray' },
            headerClass: 'header-border',
            sortable: true,
            filter: true
        },
        {
            headerName: 'Edit',
            width: '130',
            cellStyle: { borderLeft: '1px solid lightgray' },
            sortable: false,
            cellRenderer: (params) => (
                <div className='d-flex g-5'>
                    <div className='w-50'>
                        <EditIcon className='cursor APP-Color_2'
                            onClick={() => onEditConformation(params)}
                        />
                    </div>
                </div>
            ),
            headerClass: 'header-border'
        },
        {
            headerName: 'Delete',
            width: '130',
            cellStyle: { borderLeft: '1px solid lightgray' },
            sortable: false,
            cellRenderer: (params) => (
                <div className='d-flex g-5'>
                    <div className='w-50'>
                        <DeleteIcon className='cursor APP-Color_2'
                            onClick={() => onDeleteConformation(params)}
                        />
                    </div>
                </div>
            ),
            headerClass: 'header-border'
        },
    ]

    const onDeleteConformation = (row_data) => {
        setLineId(row_data.data.id)
        setTitle(`Delete Line (${row_data.data.line})`)
        setMessage(`Do you want delete this line ?`)
        setIsDeleteConformation(true)
    }

    const onDeleteLine = async () => {
        try {
            const delete_response = await deleteLine(line_id)
            if (delete_response?.status === 200) {
                successMessage(delete_response?.data?.message)
                getCompanyLineList()
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message)
        } finally {
            setLineId('')
        }
    }

    return (
        <div>
            <AppConformationModel
                open={is_delete_conformation}
                setOpen={setIsDeleteConformation}
                title={title}
                message={message}
                onConformAction={onDeleteLine}
            />
            <EditExistingLine
                open_model={is_edit_conformation}
                setOpenModel={setIsEditConformation}
                line_data={user_line_for_edit}
                setLineData={setLineDataForEdit}
                setSuccessResponse={setIsSuccessResponse}
            />

            <AddLines
                open_model={is_add_new_line}
                setOpenModel={setIsAddNewLine}
                setSuccessResponse={setIsSuccessResponse}
            />

            <main className='m-a-20'>
                <div className='d-flex justify-end'>
                    <div className='d-flex justify-between w-100'>
                        <h3 className='m-t-10 APP-color'>Lines Table</h3>
                        <Button variant='outlined' className='App-color' startIcon={<AddIcon />} onClick={() => setIsAddNewLine(true)}>Add New Line</Button>
                    </div>
                </div>
                <div
                    className="ag-theme-alpine m-t-10">
                    <AgGridReact
                        ref={gridRef}
                        rowData={line_data}
                        columnDefs={columnDefs}
                        pagination={true}
                        domLayout={'autoHeight'}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                    />
                </div>
            </main>
        </div>
    )
}

export default ManageLines