import React, { useEffect, useRef, useState, } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box, Button, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import AppConformationModel from '../../widgets/AppConformationModel';
import EditExistingUser from './EditExistingUser';
import AddNewUser from './AddNewUser';
import { errorMessage, successMessage } from '../../common/Toast';
import { useCompanyContext } from '../../common/companyContext';
import { getAllUsers, deleteUser } from '../../utils/api.users';

const ManageUsers = () => {
  const gridRef = useRef();
  const { selectedCompany } = useCompanyContext();
  const [is_add_new_user, setIsAddNewUser] = useState(false)
  const [rowData, setRowData] = useState([]);
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [user_id, setUserId] = useState('')
  const [is_delete_conformation, setIsDeleteConformation] = useState(false)
  const [user_data_for_edit, setUserDataForEdit] = useState('')
  const [is_edit_conformation, setIsEditConformation] = useState(false)
  const [is_success_response, setIsSuccessResponse] = useState(false)
  const [on_search_text, setOnSearchText] = useState('')

  useEffect(() => {
    (async () => {
      getAllUserList()
    })()
  }, [selectedCompany])

  useEffect(() => {
    (async () => {
      if (is_success_response === true) {
        getAllUserList()
        setIsSuccessResponse(false)
      }
    })()
  }, [is_success_response])

  const getAllUserList = async () => {
    try {
      const response = await getAllUsers()
      response?.data?.data && setRowData(response?.data?.data)
    } catch (error) {
      console.error(error);
    }
  }

  const onDeleteConformation = (row_data) => {
    setUserId(row_data.data.id)
    setTitle(`Delete User (${row_data.data.name})`)
    setMessage(`Do you want delete this user ?`)
    setIsDeleteConformation(true)
  }

  const onDeleteUser = async () => {
    try {
      const delete_response = await deleteUser(user_id)
      if (delete_response?.status === 200) {
        successMessage(delete_response?.data?.message)
        getAllUserList()
      }
    } catch (error) {
      errorMessage(error?.response?.data?.message)
    } finally {
      setUserId('')
    }
  }

  const onEditConformation = (row_data) => {
    setUserId(row_data.data.id)
    setIsEditConformation(true)
    setUserDataForEdit(row_data.data)
  }

  const columnDefs = [
    {
      headerName: "S No.",
      valueGetter: "node.rowIndex + 1",
      width: 78,
      sortable: true
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 300,
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Phone No',
      width: 340,
      field: 'phone_no',
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Role',
      field: 'role',
      width: 240,
      cellStyle: { borderLeft: '1px solid lightgray' },
      headerClass: 'header-border',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Edit',
      cellStyle: { borderLeft: '1px solid lightgray' },
      width: '140',
      sortable: false,
      cellRenderer: (params) => (
        <div className='d-flex g-5'>
          <div className='w-50' onClick={() => onEditConformation(params)}><EditIcon className='cursor APP-Color_2' /></div>
        </div>
      ),
      headerClass: 'header-border'
    },
    {
      headerName: 'Delete',
      cellStyle: { borderLeft: '1px solid lightgray' },
      width: '150',
      sortable: false,
      cellRenderer: (params) => (
        <div className='d-flex g-5'>
          <div className='w-50'>
            <DeleteIcon className='cursor APP-Color_2'
              onClick={() => onDeleteConformation(params)}
            />
          </div>
        </div>
      ),
      headerClass: 'header-border'
    },
  ]

  return (
    <Box style={{ width: '100%', height: '50%' }}>

      <Box>
        <AppConformationModel
          open={is_delete_conformation}
          setOpen={setIsDeleteConformation}
          title={title}
          message={message}
          onConformAction={onDeleteUser}
        />

        <AddNewUser
          open_model={is_add_new_user}
          setOpenModel={setIsAddNewUser}
          setSuccessResponse={setIsSuccessResponse}
        />

        <EditExistingUser
          open_model={is_edit_conformation}
          setOpenModel={setIsEditConformation}
          user_data={user_data_for_edit}
          setUserData={setUserDataForEdit}
          setSuccessResponse={setIsSuccessResponse}
        />
      </Box>

      <main className="m-a-20">
        <div>
          <div className='d-flex justify-end'>
            <div className='d-flex justify-between w-100'>
              <TextField className='w-25' label="Search By Name, Phone" onInput={(e) => setOnSearchText(e.target.value)} />
              {/* <Button className='black' startIcon={<DownloadIcon />}>Download Report in CSV</Button> */}
              <Button className='App-color_2' startIcon={<AddIcon />} onClick={() => setIsAddNewUser(true)}>Add New User</Button>
            </div>
          </div>
        </div>

        <div
          style={{ height: '100%', width: '100%' }}
          className="ag-theme-alpine m-t-20">
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={true}
            domLayout={'autoHeight'}
            quickFilterText={on_search_text}
            paginationPageSize={10}
            paginationPageSizeSelector={[10, 25, 50]}
          />
        </div>
      </main>
    </Box>
  );
};

export default ManageUsers