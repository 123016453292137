import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Modal, TextField, Typography, MenuItem, FormControl, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { errorMessage, successMessage } from '../../common/Toast';
import { editLine } from '../../utils/api.common';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 3,
    p: 4,
};

function EditExistingLine({ open_model, setOpenModel, line_data, setLineData, setSuccessResponse }) {
    const [line_name, setLineName] = useState('');
    const [line_type, setLineType] = useState('');
    const [line_id, setLineId] = useState('');

    useEffect(() => {
        setLineName(line_data?.line || '');
        setLineType(line_data?.process || '');
        setLineId(line_data?.id || '');
    }, [line_data]);

    const handleClose = () => {
        setOpenModel(false);
        clear();
    };

    const onHandleSubmit = async (event) => {
        event.preventDefault();
        const payload = { line_name, line_type };

        try {
            const response = await editLine(line_id, payload);
            if (response.status === 200) {
                setSuccessResponse(true);
                successMessage(response.data.message);
                handleClose();
            } else if (response?.response?.data?.error === 'Invalid User ID') {
                errorMessage('Phone number already exists.');
            }
        } catch (error) {
            errorMessage(error?.response?.data?.message || 'Something went wrong');
        }
    };

    const handleLineType = (event) => {
        setLineType(event.target.value);
    };

    const clear = () => {
        setLineName('');
        setLineType('');
        setLineId('');
        setLineData(null);
    };

    return (
        <Modal
            open={open_model}
            onClose={handleClose}
            aria-labelledby="edit-line-modal-title"
        >
            <Box sx={style}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="edit-line-modal-title" variant="h6" component="h2" className='APP-color'>
                    <strong>Edit Existing Line</strong>
                </Typography>
                <form onSubmit={onHandleSubmit}>
                    <Box>
                        <Typography variant="body1" className="gray m-t-10">Line Name</Typography>
                        <TextField
                            fullWidth
                            variant="standard"
                            className="m-t-10"
                            value={line_name}
                            onChange={(e) => setLineName(e.target.value)}
                            placeholder="Enter Line Name"
                        />
                        <Typography variant="body1" className="gray m-t-20">Line Type</Typography>
                        <FormControl fullWidth variant="standard" className="m-t-10">
                            <Select
                                value={line_type}
                                onChange={handleLineType}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select Line Type' }}
                            >
                                <MenuItem value="" disabled>
                                    Select Line Type
                                </MenuItem>
                                <MenuItem value="PRODUCTION">Production</MenuItem>
                                <MenuItem value="DISPATCH">Dispatch</MenuItem>
                            </Select>
                        </FormControl>
                        <Box className="d-flex justify-end m-t-20">
                            <Button
                                variant="contained"
                                type="submit"
                                className="APP-background_2"
                                disabled={!line_name || !line_type}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}

export default EditExistingLine;