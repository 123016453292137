import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BarChart = ({title, timeFrame, series}) => {
  const options = {
    chart: {
      type: 'column',
      backgroundColor: '#e9f0f7',
    },
    title: {
      text: title,
      align: 'left',
        },
        // subtitle: {
        //   text: 'Source: <a target="_blank" href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
        //   align: 'left',
        // },
    xAxis: {
      categories: timeFrame,
      crosshair: true,
      accessibility: {
        description: 'Time Frame',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Scans',
      },
    },
    tooltip: {
      valueSuffix: ' (Total Count)',
    },
    plotOptions: {
      column: {
        pointPadding: 0.3,
        borderWidth: 1,
      },
      series: {
        borderWidth: 0,
        dataLabels: {
            enabled: true,
            format: '{point.y}'
        }
    }
    },
    series: series
  };

  return (
    <div id="container">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarChart