import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 3,
    p: 4,
};

function AppConformationModel({ open, setOpen, title, message, onConformAction }) {
    const handleClose = () => {
        setOpen(!open)
    }
    const onhandleConfirm = () => {
        onConformAction()
        setOpen(!open)
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography className='APP-color m-t-10' id="modal-modal-title" variant="h6" component="h2">
                        <h3>{title}</h3>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {message}
                    </Typography>
                    <Box className='d-flex justify-end g-5'>
                        <Button
                            className='w-25 m-t-10 APP-background_2'
                            variant="contained"
                            onClick={onhandleConfirm}
                        >Confirm</Button>
                        <Button
                            className='w-25 m-t-10 APP-background_2'
                            variant="contained"
                            onClick={handleClose}
                        >Close</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default AppConformationModel