import React from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { displayOfBill } from './ConstantTables'

function DisplayBill() {
  const display_bill_column = displayOfBill()
  return (
    <div>
        <div
            style={{ height: '70%', margin: 10 }}
            className="ag-theme-alpine">
            <AgGridReact
                rowData={[]}
                columnDefs={display_bill_column}
                pagination={true}
                domLayout={'autoHeight'}
                paginationPageSize={8}
            />
        </div>
    </div>
)
}

export default DisplayBill