import React from 'react'
import { Route, Routes } from "react-router-dom";
import SideBar from '../sidebar/SideBar';
import Home from '../views/home/Home';
import Report from '../views/report/Report';
import Users from '../views/manage_user/ManageUsers';
import Alerts from '../views/Alerts';
import DeliveryChallan from '../views/delivery_challan/DeliveryChallan';
import AddManualData from '../views/AddManualData';
import Tracker from '../views/Tracker';
import AddCompany from '../views/AddCompany';
import AddLines from '../views/Lines/ManageLines';
import AddShifts from '../views/AddShifts';
import AddProduct from '../views/AddProduct';
import ReportResult from '../views/report/ReportResult';
import ManageDrivers from '../views/manage_driver/ManageDrivers';
import DisplayBill from '../views/DisplayBill';

function Dashboard() {
    return (
        <SideBar>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/report" element={<Report />} />
                <Route path="/report/:view" element={<ReportResult />} />
                <Route path="/users" element={<Users />} />
                <Route path="/drivers" element={<ManageDrivers />} />
                <Route path="/alert" element={<Alerts />} />
                <Route path="/delivery_challan" element={<DeliveryChallan />} />
                <Route path="/display_of_bill" element={<DisplayBill/>} />
                <Route path="/add_manual_data" element={<AddManualData />} />
                <Route path="/tracker" element={<Tracker />} />
                <Route path="/add_company" element={<AddCompany />} />
                <Route path="/add_lines" element={<AddLines />} />
                <Route path="/add_shift" element={<AddShifts />} />
                <Route path="/add_product" element={<AddProduct />} />
                <Route path="/*" element={<>Not Found</>} />
            </Routes>
        </SideBar>
    )
}

export default Dashboard