import api from "./interceptor"

export const getDeliveryChallan = async (query_payload) => {
    const params = {
        company_name: localStorage.getItem('company_name'),
        start_date: query_payload?.start_date,
        end_date: query_payload?.end_date
    }
    try {
        const response = await api.get(`/delivery_challan/v1/get_delivery_challans`, {
            params: params
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDeliveryChallanDataByChallanId = async (delivery_challan_id) => {
    try {
        const response = await api.get(`/delivery_challan/v1/get_challan_data_by_challan_id`, {
            params: { company_name: localStorage.getItem('company_name'), delivery_challan_id: delivery_challan_id }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const addNewDeliveryChallan = async (challan_payload) => {
    try {
        const response = await api.post(`/delivery_challan/v1/add_delivery_challan`, challan_payload, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}