import api from './interceptor'

export const getReportList = async (process_id) => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.get(`/report/v1/get_reports`, {
            params: { process_id: process_id, company_name: company_name }
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getProcessList = async () => {
    try {
        const company_name = localStorage.getItem('company_name');
        const response = await api.get(`/report/v1/get_process`, { params: { company_name: company_name } });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getProductionLogData = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_production_log`, { params: query_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getProductionSummaryData = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_production_summary`, { params: query_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getProductionLogCsv = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_production_log_csv`, { params: query_data , responseType: 'blob' });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getProductionSummaryCsv = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_production_summary_csv`, { params: query_data , responseType: 'blob' });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getWarehouseLogData = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_warehouse_log`, { params: query_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getWarehouseSummaryData = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_warehouse_summary`, { params: query_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getWarehouseLogCsv = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_warehouse_log_csv`, { params: query_data , responseType: 'blob' });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getWarehouseSummaryCsv = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_warehouse_summary_csv`, { params: query_data , responseType: 'blob' });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getAllVehicles = async () => {
    try {
        const response = await api.get(`/drivers/v1/get_drivers`, {
            params: { company_name: localStorage.getItem('company_name') }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDispatchLogData = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_dispatch_log`, { params: query_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDispatchSummaryData = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_dispatch_summary`, { params: query_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDispatchVerificationReport = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_verification_report`, { params: query_data });
        return response;
    } catch (error) {
        throw error;
    }
}


export const getVerificationChallanDataById = async (verification_challan_id) => {
    try {
        const response = await api.get(`/report/v1/get_verification_data_by_id`, {
            params: { company_name: localStorage.getItem('company_name'), verification_challan_id: verification_challan_id }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDispatchLogCsv = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_dispatch_log_csv`, { params: query_data , responseType: 'blob' });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDispatchSummaryCsv = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_dispatch_summary_csv`, { params: query_data , responseType: 'blob' });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getMissingBoxData = async (query_data) => {
    try {
        const response = await api.get(`/report/v1/get_missing_box_log`, { params: query_data });
        return response;
    } catch (error) {
        throw error;
    }
}

export const getImageUrl = async (image_key) => {
    try {
        const response = await api.get(`/dashboard/v1/getImageUrl`, { params: { image_key: image_key } });
        return response;
    } catch (error) {
        return error;
    }
}