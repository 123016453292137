import React, { useEffect, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { deliveryChallanColumn } from '../ConstantTables';
import { Box, TextField } from '@mui/material';
// import { Button } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import AddDeliveryChallan from './AddDeliveryChallan';
import { getDeliveryChallan } from '../../utils/api.delivery.challan';
import ProductDetailsOfDC from './ProductDetailsOfDC';
import { useCompanyContext } from '../../common/companyContext';
import DatePicker from 'react-multi-date-picker';
import { endTimeDateFormate, startTimeDateFormate } from '../../utils/functions';

function DeliveryChallan() {
    const gridRef = useRef();
    const { selectedCompany } = useCompanyContext();
    const delivery_challan_column = deliveryChallanColumn()
    const [delivery_challans, set_delivery_challans] = useState([])
    // const [is_open_delivery_model, set_is_open_delivery_model] = useState(false)
    // const [is_success_response, setIsSuccessResponse] = useState(false)
    const [on_search_text, setOnSearchText] = useState('')
    const [is_show_complete_details, set_is_show_complete_details] = useState(false)
    const [selected_row, set_selected_row] = useState('')
    const [start_date, set_start_date] = useState('')
    const [end_date, set_end_date] = useState('')

    useEffect(() => {
        (async () => {
            await getAllDeliveryChallanList()
        })()
    }, [selectedCompany, end_date])

    // useEffect(() => {
    //     (async () => {
    //         if (is_success_response === true) {
    //             await getAllDeliveryChallanList()
    //             setIsSuccessResponse(false)
    //         }
    //     })()
    // }, [is_success_response])

    const getAllDeliveryChallanList = async () => {
        try {
            const query_payload = {
                start_date: start_date && startTimeDateFormate(start_date),
                end_date: end_date && endTimeDateFormate(end_date),
            }
            const response = await getDeliveryChallan(query_payload);
            if (response?.data?.data) {
                set_delivery_challans(response?.data?.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onShowDetailsData = async (data) => {
        try {
            set_selected_row(data.data)
            set_is_show_complete_details(true)
        } catch (error) {
            console.log(error);
        }
    }

    const onHandleInputDate = (dateRange) => {
        if (dateRange && dateRange.length >= 2) {
            set_start_date(dateRange[0]?.format() ? dateRange[0]?.format() : '')
            set_end_date(dateRange[1]?.format() ? dateRange[1]?.format() : '')
        }
    }
    return (
        <div>
            {/* <Box>
                <AddDeliveryChallan open_model={is_open_delivery_model} setOpenModel={set_is_open_delivery_model} setSuccessResponse={setIsSuccessResponse} />
            </Box> */}
            <Box>
                <ProductDetailsOfDC open_model={is_show_complete_details} setOpenModel={set_is_show_complete_details} selected_row={selected_row} />
            </Box>
            <main className="m-a-20">
            <div>
                    <div className='d-flex justify-end'>
                        <div className='d-flex justify-between w-100'>
                            <TextField
                                className='w-30'
                                label="Search By Name, Phone, Vehicle No."
                                variant="outlined"
                                inputProps={{
                                    style:{
                                        height: '1px',
                                        padding: '25px'
                                    }
                                }}
                                onInput={(e) => setOnSearchText(e.target.value)}
                            />
                            {/* <Button className='black' startIcon={<AddIcon />} onClick={() => set_is_open_delivery_model(true)}>Add Delivery Challan</Button> */}
                            <DatePicker
                                range
                                required
                                rangeHover
                                placeholder=" Start Date ~ End Date "
                                format="DD/MM/YYYY"
                                onChange={onHandleInputDate}
                                value={[start_date, end_date]}
                                style={{ padding: '22px 45px', height: '46px', background: '#e9f0f7', margin: '5px 0px 0 0' }}
                            // maxDate={new Date().setDate(new Date().getDate() + 1)}
                            />
                        </div>
                    </div>
                </div>

                <div
                    style={{ height: '100%', width: '100%' }}
                    className="ag-theme-alpine m-t-20">
                    <AgGridReact
                        ref={gridRef}
                        rowData={delivery_challans}
                        columnDefs={delivery_challan_column}
                        pagination={true}
                        quickFilterText={on_search_text}
                        domLayout={'autoHeight'}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                        onRowClicked={(row) => onShowDetailsData(row)}
                        getRowStyle={(row) => ({ cursor: 'pointer' })}
                    />
                </div>
            </main>
        </div>
    )
}

export default DeliveryChallan