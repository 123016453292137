import React, { useEffect, useRef, useState } from 'react'
import { Button, TextField, Modal, Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useCompanyContext } from '../../common/companyContext';
import { addLine, getLineList } from '../../utils/api.common';
import { errorMessage, successMessage } from '../../common/Toast';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function AddLines({ open_model, setOpenModel, setSuccessResponse }) {
    const gridRef = useRef();
    const [line_name, setLineName] = useState('')
    const { selectedCompany } = useCompanyContext();
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [line_id, setLineId] = useState('')
    const [line_data, setLineData] = useState([])
    const [line_type, setLineType] = useState('PRODUCTION');
    const [is_success_response, setIsSuccessResponse] = useState(false)

    useEffect(() => {
        (async () => {
            getCompanyLineList()
        })()
    }, [selectedCompany])

    useEffect(() => {
        (async () => {
            if (is_success_response === true) {
                getCompanyLineList()
                setIsSuccessResponse(false)
            }
        })()
    }, [is_success_response])

    const getCompanyLineList = async () => {
        try {
            const response = await getLineList(selectedCompany)
            response?.data?.data && setLineData(response?.data?.data)
        } catch (error) {
            console.error(error);
        }
    }

    const addNewLine = async (event) => {
        event.preventDefault()
        try {
            const line_data = { line_name: line_name, line_type: line_type }
            const response = await addLine(line_data)
            if (response.status === 200) {
                successMessage(response.data.message)
                setSuccessResponse(true)
                getCompanyLineList()
                setLineName('')
            }
        } catch (error) {
            errorMessage(error.response.data.error)
            console.error(error);
        } finally {
            handleClose()
        }
    }

    const handleLineType = (event) => {
        setLineType(event.target.value);
    };

    const handleClose = () => {
        setOpenModel(false)
        clear()
    };

    const clear = () => {
        setLineName('')
        setLineType('PRODUCTION')
    }

    return (
        <div>
            <Modal
                open={open_model}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" component="h2" className='APP-color'>
                            <strong>Add New Line</strong>
                        </Typography>
                    </div>

                    <form onSubmit={addNewLine}>
                        <div>
                            <TextField
                                className='w-100 m-t-10'
                                onChange={(e) => setLineName(e.target.value)}
                                placeholder='Enter Line Name'
                                variant="standard"
                                value={line_name}
                            />
                        </div>
                        <div className='w-100 m-t-20'>
                            <h5 className='gray m-t-10'>Line Type</h5>
                            <FormControl variant="standard" className='w-100 m-t-10'>
                                <Select
                                    value={line_type}
                                    onChange={handleLineType}
                                >
                                    <MenuItem value="" disabled>
                                        Select Line Type
                                    </MenuItem>
                                    <MenuItem value={'PRODUCTION'}>Production</MenuItem>
                                    <MenuItem value={'DISPATCH'}>Dispatch</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='d-flex justify-end m-t-20'>
                            <Button className={line_name ? 'APP-background_2' : ''} variant="contained" disabled={!line_name || !line_type} type='submit'>Add Line</Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div >
    )
}

export default AddLines