import { useEffect, useState } from 'react';
import { Box, IconButton, Modal, Table, Typography, TableBody, TableCell, TableHead, TableRow, LinearProgress, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ShowIndianStandardTime } from '../../common/common';
import { getVerificationChallanDataById } from '../../utils/api.report';

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

function ShowVerificationReportDetails({ open_model, setOpenModel, selected_row }) {
    const [delivery_challan_by_challan_id, set_delivery_challan_by_challan_id] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        (async () => {
            if (selected_row?.id) {
                const response = await getVerificationChallanDataById(selected_row.id)
                if (response.data.data) {
                    set_delivery_challan_by_challan_id(response.data.data)
                    setIsLoading(false)
                }
            }
        })()
    }, [selected_row.id])

    return (
        <div>

            <Box>
                <Modal
                    open={open_model}
                    onClose={() => setOpenModel(false)}
                >
                    <Box sx={style}>
                        <Box className='w-100'>
                            <Box className="d-flex justify-between">
                                <IconButton
                                    aria-label="close"
                                    onClick={() => setOpenModel(false)}
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Box className="w-100 text-center">
                                    <Typography variant="h6" component="h1"><b>Verification Report</b></Typography>
                                </Box>
                            </Box>
                            <Box className="m-t-10">
                                <Box className="d-flex justify-between">
                                    <Typography variant="h6" component="h1">
                                        <p>Date: <b>{ShowIndianStandardTime(selected_row.date).date} {ShowIndianStandardTime(selected_row.date).time}</b></p>
                                    </Typography>
                                    <Box>
                                        <p className={`p-10 ${selected_row.status === 'VERIFIED, OK' ? 'bg-green b-5 white' : 'bg-red b-5 white'}`}>
                                            {selected_row.status}
                                        </p>
                                    </Box>
                                </Box>
                                <Typography variant="h6" component="h1">
                                    <p>Vehicle: <b>{selected_row.vehicle_no}</b></p>
                                </Typography>
                                <Typography variant="h6" component="h1">
                                    <p>Challan No(s): <b>{selected_row.challan_ids}</b></p>
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            {delivery_challan_by_challan_id.length && <Table className='b-5 m-t-15'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='m-0 p-0 p-l-15 p-5 dark-blue'><strong>Product Name</strong></TableCell>
                                        <TableCell className='m-0 p-0 p-l-15 dark-blue'><strong>Sku Size</strong></TableCell>
                                        <TableCell className='m-0 p-0 p-l-15 dark-blue'><strong>DC Box Count</strong></TableCell>
                                        <TableCell className='m-0 p-0 p-l-15 dark-blue'><strong>Actual Box Count</strong></TableCell>
                                        <TableCell className='m-0 p-0 p-l-15 dark-blue'><strong>Remaining Box Count</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                {isLoading
                                    ? <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={5} className='m-0 p-0'>
                                                <LinearProgress className='w-100' />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    : <TableBody>
                                        {delivery_challan_by_challan_id?.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell className='m-0 p-0 p-l-15 p-5'>{item.product}</TableCell>
                                                <TableCell className='m-0 p-0 p-l-15'>{item.sku_size}</TableCell>
                                                <TableCell className='m-0 p-0 p-l-15'>{item.box_count}</TableCell>
                                                <TableCell className='m-0 p-0 p-l-15'>{item.actual_box_count ? item.actual_box_count : '-'}</TableCell>
                                                <TableCell className='m-0 p-0 p-l-15'>{item.box_count ? (item.box_count - item.actual_box_count) : '-'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                }
                            </Table>}
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </div>
    )
}

export default ShowVerificationReportDetails