import React, { useCallback, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { alertMissingColumn } from './ConstantTables';
import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getMissingBoxData } from '../utils/api.report';
import { useCompanyContext } from '../common/companyContext';
import { useLocation } from 'react-router-dom';
import { endTimeDateFormate, startTimeDateFormate } from '../utils/functions';
import { errorMessage } from '../common/Toast';

function Alerts() {
  const pagination_page_size = 50;
  const location = useLocation();
  const { selectedCompany } = useCompanyContext();
  const [gridApi, setGridApi] = useState(null);

  const column = alertMissingColumn();

  const datasource = {
    async getRows(params) {
      const { startRow, endRow } = params.request;
      const pageSize = endRow - startRow;
      const pageIndex = Math.floor(startRow / pageSize) + 1;

      let query_data;
      if (location?.state) {
        query_data = {
          start_date: startTimeDateFormate(location?.state?.start_date),
          end_date: endTimeDateFormate(location?.state?.end_date),
          company_name: selectedCompany,
          page_size: pageSize,
          page_index: pageIndex
        };
      } else {
        query_data = {
          company_name: selectedCompany,
          page_size: pageSize,
          page_index: pageIndex
        };
      }

      try {
        const response = await getMissingBoxData(query_data);
        if (!response.data.data.missing_data.length) {
          params.api.showNoRowsOverlay();
        }
        params.success({
          rowData: response.data.data.missing_data,
          rowCount: +response.data.data.total_count,
        });
      } catch (error) {
        params.fail();
        errorMessage("Error fetching or processing data");
      }
    }
  };

  const onHandleReturnPage = () => {
    window.history.back();
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.setGridOption("serverSideDatasource", datasource);
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption("serverSideDatasource", datasource);
    }
  }, [selectedCompany, gridApi, datasource]);

  return (
    <div className='m-a-30'>
      <Box className='d-flex'>
        {location?.state && <ArrowBackIcon className='m-r-10 cursor' onClick={onHandleReturnPage} />}
        <h4>Missing Scanned Box</h4>
      </Box>
      <div
        style={{ height: '70%' }}
        className="ag-theme-alpine m-t-10">
        <AgGridReact
          columnDefs={column}
          pagination={true}
          paginationPageSize={10}
          domLayout="autoHeight"
          rowModelType="serverSide"
          onGridReady={onGridReady}
          cacheBlockSize={pagination_page_size}
          overlayNoRowsTemplate={'<b>No Row To Display</b>'}
          paginationPageSizeSelector={[10, 25, 50, 100]}
        />
      </div>
    </div>
  );
}

export default Alerts;