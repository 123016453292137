import React, { useCallback, useEffect, useState } from 'react'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box, Button } from '@mui/material';
import DatePicker from 'react-multi-date-picker';
import DropDown from '../../widgets/DropDown';
import { useCompanyContext } from '../../common/companyContext';
import { getAllVehicles, getProcessList, getReportList } from '../../utils/api.report';
import ReportResult from './ReportResult';
import { qrReadData, weightStatusData } from './constant';
import { getLineList, getProductList, getShiftList } from '../../utils/api.common';

function Report() {
  const { selectedCompany } = useCompanyContext();
  const [is_show_result_table, set_is_show_result_table] = useState(false)
  const [shifts, set_shift_list] = useState([])
  const [selected_shift, set_selected_shift] = useState('')
  const [lines, set_line_list] = useState([])
  const [selected_line, set_selected_line] = useState('')
  const [process_list, set_process_list] = useState([])
  const [selected_process, set_selected_process] = useState('')
  const [report_list, set_report_list] = useState([])
  const [selected_report, set_selected_report] = useState('')
  const [selected_weight, set_selected_weight] = useState('')
  const [selected_vehicle, set_selected_vehicle] = useState('')
  const [product_list, set_product_list] = useState([])
  const [vehicle_list, set_vehicle_list] = useState([])
  const [selected_product, set_selected_product] = useState('')
  const [selected_qr, set_selected_qr] = useState({
    id: 1,
    qr_code: true,
    title: "Yes",
  })
  const [start_date, set_start_date] = useState('')
  const [end_date, set_end_date] = useState('')
  const qr_code_list = qrReadData()
  const weight_status_list = weightStatusData()

  useEffect(() => {
    (async () => {
      set_is_show_result_table(false)
      await getProcessData()
      await getShiftData()
    })()
  }, [selectedCompany])

  const getProcessData = async () => {
    try {
      const response = await getProcessList()
      response?.data?.data && set_process_list(response?.data?.data)
    } catch (error) {
      console.error(error);
    }
  }

  const getReportData = useCallback(async () => {
    try {
      set_selected_report({ id: '' })
      const process_id = selected_process?.id
      const response = process_id && await getReportList(process_id)
      response?.data?.data && set_report_list(response?.data?.data)
    } catch (error) {
      console.error(error);
    }
  },[selected_process?.id])

  const getProductData = useCallback(async () => {
    try {
      set_selected_product({ id: '' })
      set_selected_vehicle({ id: '' })
      set_selected_weight({ id: '' })
      set_selected_line({ id: '' })
      set_vehicle_list([])  
      const response = await getProductList()
      response?.data?.data && set_product_list(response?.data?.data)
    } catch (error) {
      console.error(error);
    }
  }, [])

  const getLineData = useCallback(async () => {
    try {
      const response = await getLineList()
      response?.data?.data && set_line_list(response?.data?.data)
    } catch (error) {
      console.error(error);
    }
  }, [])

  const getShiftData = async () => {
    try {
      const response = await getShiftList()
      response?.data?.data && set_shift_list(response?.data?.data)
    } catch (error) {
      console.error(error);
    }
  }

  const getAllVehiclesData = useCallback(async () => {
    try {
      const response = await getAllVehicles()
      response?.data?.data && set_vehicle_list(response?.data?.data)
    } catch (error) {
      console.error(error);
    }
  }, [])

  useEffect(() => {
    (async () => {
      await getReportData()
      await getProductData()
      await getLineData()
      if (selected_process?.process_name === 'Dispatch') {
        await getAllVehiclesData()
      }
    })()
  }, [selected_process?.process_name, getLineData, getReportData, getProductData, getAllVehiclesData])

  const onHandleInputDate = (dateRange) => {
    if (dateRange && dateRange.length >= 2) {
      set_start_date(dateRange[0]?.format() ? dateRange[0]?.format() : '')
      set_end_date(dateRange[1]?.format() ? dateRange[1]?.format() : '')
    }
  }

  return (
    <div>
      {is_show_result_table
        ? <Box>
          <ReportResult
            start_date={start_date}
            end_date={end_date}
            process_name={selected_process.process_name}
            report_name={selected_report.report_name}
            shift={selected_shift?.shift_name}
            line={selected_line?.id}
            product_name={selected_product?.product_name}
            sku_size={selected_product?.sku_size}
            set_is_show_result_table={set_is_show_result_table}
            vehicle_no={selected_vehicle?.vehicle_no}
            weight_status={selected_weight?.weight_status}
            is_qr_read={selected_qr.qr_code}
            is_home_report_route_back={true}
          />
        </Box>
        : <Box sx={{ padding: '30px' }}>
          <form onSubmit={() => set_is_show_result_table(true)}>
            <div className='d-flex justify-end m-r-20'>
              <DatePicker
                range
                required
                rangeHover
                placeholder=" Start Date ~ End Date "
                format="DD/MM/YYYY"
                onChange={onHandleInputDate}
                value={[start_date, end_date]}
                style={{ padding: '20px 27px', height: '35px', background: '#e9f0f7' }}
              // maxDate={new Date().setDate(new Date().getDate() + 1)}
              />
            </div>

            <div className='d-flex justify-between m-t-10'>
              <div className='d-flex justify-between w-50 p-10'>
                <p className='black m-t-10'>Shift</p>
                <DropDown
                  required={false}
                  title={'Shift'}
                  list={shifts}
                  item_name_key={'shift_name'}
                  selected_value={selected_shift}
                  set_selected_result={set_selected_shift}
                />
              </div>

              <div className='d-flex justify-between w-50 p-10'>
                <p className='black m-t-10'>Factory Name</p>
                <DropDown
                  required={true}
                  title={'Factory*'}
                  disabled={true}
                />
              </div>
            </div>

            <div className='d-flex justify-between m-t-10'>
              <div className='d-flex justify-between w-50 p-10'>
                <p className='black m-t-10'>Process</p>
                <DropDown
                  required={true}
                  title={'Process*'}
                  list={process_list}
                  item_name_key={'process_name'}
                  selected_value={selected_process}
                  set_selected_result={set_selected_process}
                />
              </div>

              <div className='d-flex justify-between w-50 p-10'>
                <p className='black m-t-10'>Report</p>
                <DropDown
                  required={true}
                  title={'Report*'}
                  list={report_list}
                  item_name_key={'report_name'}
                  set_selected_result={set_selected_report}
                  selected_value={selected_report}
                  disabled={!selected_process?.id}
                />
              </div>
            </div>

            <div className='d-flex justify-between m-t-10'>
              <div className='d-flex justify-between w-50 p-10'>
                <p className='black m-t-10'>Line Number</p>
                <DropDown
                  required={false}
                  title={'Line No'}
                  list={lines}
                  item_name_key={'line'}
                  selected_value={selected_line}
                  set_selected_result={set_selected_line}
                />
              </div>

              <div className='d-flex justify-between w-50 p-10'>
                <p className='black m-t-10'>QR Read</p>
                <DropDown
                  required={false}
                  disabled={selected_process.process_name !== "Warehouse"}
                  title={'QR Read'}
                  list={qr_code_list}
                  item_name_key={'title'}
                  selected_value={selected_qr}
                  set_selected_result={set_selected_qr}
                />
              </div>
            </div>

            <div className='d-flex justify-between m-t-10'>
              <div className='d-flex justify-between w-50 p-10'>
                <p className='black m-t-10'>Product Name</p>
                <DropDown
                  required={false}
                  title={'Product'}
                  list={product_list}
                  item_name_key={'product_name'}
                  item_name_key_2={'sku_size'}
                  selected_value={selected_product}
                  set_selected_result={set_selected_product}
                  disabled={!selected_process?.id}
                />
              </div>

              <div className='d-flex justify-between w-50 p-10'>
                <p className='black m-t-10'>Weight Status</p>
                <DropDown
                  required={false}
                  title={'Weight Status'}
                  list={weight_status_list}
                  item_name_key={'title'}
                  selected_value={selected_weight}
                  set_selected_result={set_selected_weight}
                />
              </div>
            </div>

            <div className='d-flex justify-between m-t-10'>
              {selected_process.process_name === 'Dispatch' && <div className='d-flex justify-between w-50 p-10'>
                <p className='black m-t-10'>Vehicle No</p>
                <DropDown
                  required={false}
                  title={'Vehicle No'}
                  list={vehicle_list}
                  item_name_key={'vehicle_no'}
                  selected_value={selected_vehicle}
                  set_selected_result={set_selected_vehicle}
                  disabled={!vehicle_list.length}
                />
              </div>}
            </div>

            <Box>
              <div className='d-flex justify-center'>
                <Button
                  className='w-25 m-t-30'
                  style={{ backgroundColor: 'rgb(11, 11, 116)' }}
                  variant="contained"
                  type='submit'
                >Show</Button>
              </div>
            </Box>
          </form>
        </Box>}
    </div >
  )
}

export default Report