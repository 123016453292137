export const qrReadData = () => {
    return [
        {
            id: 1,
            qr_code: true,
            title: "Yes",
        },
        {
            id: 2,
            qr_code: false,
            title: "No",
        }
    ]
}

export const weightStatusData = () => {
    return [
        {
            id: 1,
            weight_status: "under_weighted",
            title: "Under Weight",
            
        },
        {
            id: 2,
            weight_status: "good",
            title: "Good",
        },
        {
            id: 3,
            weight_status: "over_weighted",
            title: "Over Weight",
        }
    ]
}